<template>

  <validation-observer ref="simpleRules">
    <b-form
      @submit.prevent="postDepartmentData"
      @reset.prevent="OnReset"
    >
      <b-row>
        <!-- <b-col cols="12">
        <b-form-group
          label="Nomor Induk Jemaat"
          label-for="nij"
          label-cols-md="4"
        >
          <b-form-input
            id="nij"
            v-model="formData.nij"
            type="text"
            placeholder="Nomor Induk Jemaat"
          />
        </b-form-group>
      </b-col> -->
        <b-col cols="12">
          <b-form-group
            label="Name *"
            label-for="name"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="name"
            >
              <b-form-input
                id="name"
                v-model="formData.name"
                :state="errors.length > 0 ? false:null"
                type="text"
                placeholder="Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- <b-col cols="12">
          <b-form-group
            label="Address"
            label-for="address"
            label-cols-md="4"
          >
            <b-form-textarea
              id="address"
              v-model="formData.address"
              type="text"
              placeholder="Address"
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Email *"
            label-for="email"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="email"
            >
              <b-form-input
                id="email"
                v-model="formData.email"
                :state="errors.length > 0 ? false:null"
                type="email"
                placeholder="Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Abbreviation"
            label-for="abbreviation"
            label-cols-md="4"
          >
            <b-form-input
              id="abbreviation"
              v-model="formData.abbreviation"
              type="text"
              placeholder="Abbreviation"
            />
          </b-form-group>
        </b-col> -->
        <!-- <b-col cols="12">
          <b-form-group
            label="Phone"
            label-for="phone"
            label-cols-md="4"
          >
            <b-form-input
              id="phone"
              v-model="formData.phone"
              type="number"
              placeholder="Phone"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Gender"
            label-for="gender"
            label-cols-md="4"
          >
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="formData.gender"
                name="some-radios"
                value="1"
              >
                Male
              </b-form-radio>
              <b-form-radio
                v-model="formData.gender"
                name="some-radios"
                value="0"
              >
                Female
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Date Of Birth"
            label-for="date_of_birth"
            label-cols-md="4"
          >
            <b-form-datepicker
              id="date_of_birth"
              v-model="formData.date_of_birth"
              placeholder="Date Of Birth"
              local="en"
              initial-date=""
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Join Year"
            label-for="join_year"
            label-cols-md="4"
          >
            <b-form-datepicker
              id="join_year"
              v-model="formData.join_year"
              placeholder="Join Year"
              local="en"
              :date-format-options="{ year: 'numeric', month: undefined, day: undefined }"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Profession"
            label-for="profession"
            label-cols-md="4"
          >
            <b-form-input
              id="profession"
              v-model="formData.profession"
              type="text"
              placeholder="Profession"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Marriage Status"
            label-for="marriage_status"
            label-cols-md="4"
          >
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="formData.marriage_status"
                name="some-radios"
                value="A"
              >
                Married
              </b-form-radio>
              <b-form-radio
                v-model="formData.marriage_status"
                name="some-radios"
                value="B"
              >
                Not Married
              </b-form-radio>
              <b-form-radio
                v-model="formData.marriage_status"
                name="some-radios"
                value="C"
              >
                Divorced
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Date Of Marriage"
            label-for="date_of_marriage"
            label-cols-md="4"
          >
            <b-form-datepicker
              id="date_of_marriage"
              v-model="formData.date_of_marriage"
              placeholder="Date Of Marriage"
              local="en"
              initial-date="2000-06-01"
            />
          </b-form-group>
        </b-col> -->
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="church"
          >
            <b-form-group
              label="Church *"
              label-cols-md="4"
              :state="errors.length > 0 ? false:null"
            >

              <v-select
                v-model="formData.church"
                placeholder="Please Select Church"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="churchData"
                label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- <b-col cols="12">
          <b-form-group
            label="Department"
            label-cols-md="4"
          >
            <v-select
              v-model="formData.department"
              placeholder="Please Select Department"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="depatmentData"
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Position"
            label-cols-md="4"
          >
            <v-select
              v-model="formData.position"
              placeholder="Please Select Position"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="positionData"
              label="name"
            />
          </b-form-group>
        </b-col> -->
        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>

    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// import formValidation from '@core/comp-functions/forms/form-validation'
// import { ref } from '@vue/composition-api'
// import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import axios from 'axios'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    BForm,
    BButton,
    // BFormRadio,
    // BCardText,
    // BFormTextarea,
    // BFormDatepicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      churchData: [],
      // departmentData: [],
      // positionData: [],
      formData: {},
      required,
    }
  },
  mounted() {
    this.getChurchData()
    // this.getDepartmentData()
    // this.getPositionData()
  },
  methods: {
    getChurchData() {
      axios
        .get('/churches')
        .then(res => {
          this.churchData = res.data.data
        })
      // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    },
    // getDepartmentData() {
    //   axios
    //     .get('/departments')
    //     .then(res => {
    //       this.depatmentData = res.data.data
    //     })
    //   // eslint-disable-next-line no-console
    //     .catch(err => console.log(err))
    // },
    // getPositionData() {
    //   axios
    //     .get('/positions')
    //     .then(res => {
    //       this.positionData = res.data.data
    //     })
    //   // eslint-disable-next-line no-console
    //     .catch(err => console.log(err))
    // },
    postDepartmentData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$swal({
            title: 'Please Wait..',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            },
          }).then(
            this.formData.church_id = this.formData.church.id,
            delete this.formData.church,
            axios.post('/departments', this.formData).then(res => {
              this.form = {}
              // eslint-disable-next-line no-console
              console.log('res', res)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Your data has been saved.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(() => {
                this.$router.push({ name: 'department-all' })
              })
            }),
          )
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.demo-inline-spacing > * {
    margin-top: 0.5rem;
}
</style>
